import { useEffect, useState } from "react";
import axios from "axios";
import { fetchDeviceAndIPInfo, logout, ssoLogout, checkIf2FAEnabled, verifyToken } from "../../controller/registerController";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { saveDataToLocalStorage } from "../../localStorageComp/storage";
import AudioRecorderComponent from "../audioRecorder/audioRecorderComponent";
import SOAPNoteFrame from "./soapNote/soapNoteComponent";
import SoapNoteHistoryComponent from "./soapNote/soapNoteHistory/soapNoteHistort";
import ProductAppBar from "../productAppBar/ProductAppBar";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Paper, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import getApiUrl from "../../components/config";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, userActions } from "../../store/user";
import { AppDispatch, RootState } from "../../store";
import Cookies from 'js-cookie';
import logger from "../../logger/loggerConfig";
import { useData } from "../../context/dataContext";
import DarkMode from "../../utils/color/DarkMode";
import { userDetailsActions } from "../../store/userDetails";
interface MyNotesComponentProps {
  recording: (b: boolean) => void;
}
const MyNotesComponent: React.FC<MyNotesComponentProps> = ({ recording }) => {
  const navigate = useNavigate();
  const [recordings, setRecordings] = useState<Blob[]>([]);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [showProcessing, setShowProcessing] = useState<boolean>(false);
  const [transcription, setTranscription] = useState<any>();
  const [component, setComponent] = useState<any>();
  const [showTranscription, setShowTranscription] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  const [isProfileClicked,setIsProfileClicked] = useState<boolean>(false);
  const [isAudioDialogOpen , setIsAudioDialogOpen] = useState<boolean>(false); //S- Added state for dialog to handle recorded audio session
  const [CheckTwoFA,setCheckTwoFA]=useState(false);
  const [twoFADialogOpen,setTwoFADialogOpen]=useState(false);
  const [token,settoken]=useState("");
  const [tokenerror,settokenerror]=useState("");
  const [isImage1, setIsImage1] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const userFromStore = useSelector((store : RootState)=>store.user);
  const SERVER_URL = getApiUrl(process.env.REACT_APP_URL);
  const { globalData } = useData();
  const handleToggle = () => {
    setIsImage1((prev) => !prev);
  };
  const [isGetUserDone, setIsGetUserDone] = useState(false);
  const [reloadAppBar,setReloadAppBar] = useState(false);
  useEffect(() => {
    const initializeUser = async () =>{
      if (!isGetUserDone) {
        await getUser();
        setIsGetUserDone(true);
      }
      // const userData: any = await getDataFromLocalStorage("user");
      // if (!userData?.token) {
      //   navigate("/");
      // }else if(!userData.isSubscribed){
      //   navigate("/subscription");
      // }
      setIsUserInitialized(true);
    }
    initializeUser();
  }, []);

  useEffect(()=>{
    const isLogIn = Cookies.get('isLogIn');
    logger.debug("cookie token in notes comp domain updated",isLogIn);
    if(!isLogIn){
      dispatch(userActions.deleteUser());
      dispatch(userDetailsActions.deleteUserDetails());
      navigate("/");
    }
    else if(userFromStore.status === 'idle'){
      dispatch(fetchUser());
    }
    else if(userFromStore.status === 'failed'){
      navigate("/");
    }
    else if(userFromStore.status === 'succeeded'){
      if(!userFromStore.data?.isSubscribed){
        navigate("/subscription");
      }
      // console.log("Hello");
      // console.log(CheckTwoFA);
      if(CheckTwoFA){
        // console.log("I am in");
        // console.log(userFromStore.data?.id);
        checkIfTwoFAEnabled();
      }
      
    }
  },[dispatch,userFromStore.status,CheckTwoFA])


  const checkIfTwoFAEnabled = async () =>{
    const payload={
      id:userFromStore.data?.id,
    }

    let data = await checkIf2FAEnabled(payload);
    // console.log(data.message);
    if(data.message==="Disable 2FA"){
      // console.log("opening");
      setTwoFADialogOpen(true);
    }
    setCheckTwoFA(false);

  }
  const getUser = async () => {
    // by the time user comes through manual login locastorage should be filled with user details and token so this function doesn't need to execute
    
    let token;
    if(searchParams.has('token')) token = searchParams.get('token');
    if(!token){
      return;
    }
    setSearchParams({});
    setIsGetUserDone(true);
    const base_Url = getApiUrl(process.env.REACT_APP_URL);
    const url = `${base_Url}/api/auth/login/success`;
    // const url = `http://localhost:8000/api/auth/login/success`;
    const DeviceInfo = await fetchDeviceAndIPInfo();
    const{deviceBrand,deviceModel,city,state} = DeviceInfo;


    const Info = deviceBrand;
    const ip = (`${city},${state}`);
    try {
      let config;
      if(token) {
        config = {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      }
      else config = { withCredentials: true
       }
      const devData ={
          Info,ip
      }
      const response = await axios.post(url,devData, config,);
      const { data } = response;
      if (!data.error) {
        // await saveDataToLocalStorage("user", {
        //   email: data.data.user.email,
        //   fullname : data.data.user.fullname,
        //   id : data.data.user.id,
        //   isSubscribed : data.data.user.isSubscribed,
        //   token: data.token,
        //   login : data.data?.user?.login,
        // });
        const payload = {
          email: data.data.user.email,
          fullname : data.data.user.fullname,
          id : data.data.user.id,
          isSubscribed : data.data.user.isSubscribed,
          login : data.data.user.login,
          subscriptionInfo : data.data.user.subscriptionInfo,
        };
        dispatch(userActions.setUser(payload));
        // console.log("setting to true");
        setCheckTwoFA(true);
        setReloadAppBar(true);  //this will reload the component after adding the user to localstorage in case of google sign in
      }
    } catch (error) {
      logger.error("Error fetching user data:", error);
    }
  };

  const handleTranscriptionUpdate = (newTranscription: string) => {
    if (
      newTranscription !== null &&
      newTranscription !== undefined &&
      newTranscription !== ""
    ) {
      setTranscription(newTranscription);
      setShowTranscription(true);
    } else {
      throw Error("Error while transcribing");
    }
  };

  const handleComponentUpdate = (comp: string) => {
    if (comp !== null && comp !== undefined && comp !== "") {
      setComponent(comp);
    }
  };

  const handleVerifyTokenFA = async () =>{
    // console.log(token);
    // console.log(userFromStore.data.id);
    const payload = {
      id:userFromStore.data?.id,
      token:token
    }
    const response = await verifyToken(payload);
    if(response.message==="true"){
      setTwoFADialogOpen(false);
    }
    else{
      settokenerror("invalid Code");
    }
  }

  const handleTokenChange = (e:any) =>{
    settoken(e.target.value);
  }
  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <ProductAppBar
        open={open}
        showMenu={true}
        setOpen={setOpen}
        isRecording = {isRecording}
        showProcessing = {showProcessing}
        recordings = {recordings}
        setIsProfileClicked = {setIsProfileClicked}
        setIsAudioDialogOpen = {setIsAudioDialogOpen}
      />
      <Grid
        component={Paper}
        container
        columnGap={0}
        sx={{
          // height: 'calc(100%-64px)', 
          height:"90.3%",
          justifyContent: "center",
          overflowY: 'scroll',
          scrollbarWidth: 'none', 
          '&::-webkit-scrollbar': {
            display: 'none' 
          }
        }}
      >
        
        <Grid xs={1} sm={1} md={3}>
          {/* <Button onClick={() => setShowTranscription(false)} style={{marginLeft: '77%',marginTop: '2%',marginBottom: '2%', backgroundColor: '#0085FE', color: 'white', borderRadius: '10px', border: 'none', padding: '8px 16px'  }}>
            <AddIcon></AddIcon>New Note
          </Button> */}
          {isUserInitialized && (
          <SoapNoteHistoryComponent
            show = {setShowTranscription}
            open={open}
            setOpen={(newOpen: boolean) => setOpen(newOpen)}
            onTranscriptionUpdate={handleTranscriptionUpdate}
            onComponentUpdate={handleComponentUpdate}
            recordings = {recordings}
            setRecordings = {setRecordings}
            isRecording = {isRecording}
            showProcessing = {showProcessing}
          />
          )}
        </Grid>
        <Grid
          xs={11}
          sm={11}
          md={9}
          sx={{
            // height: "85vh",
            mt: -2,
            flexGrow: 1,
            width: "100%",
            maxWidth: "100%",
            flexShrink: 0
          }}
        >
          {showTranscription ? (
            <SOAPNoteFrame
              transcriptionData={transcription}
              componentUpdate={component}
            />
          ) : (
            <>
              <AudioRecorderComponent
                onTranscriptionUpdate={handleTranscriptionUpdate}
                onComponentUpdate={handleComponentUpdate}
                recordings={recordings}
                setRecordings={setRecordings}
                isRecording = {isRecording}
                setIsRecording={setIsRecording}
                idleBox = {recording}
                showProcessing = {showProcessing}
                setShowProcessing={setShowProcessing}
                dialogOpen = {isAudioDialogOpen}
                setDialogOpen = {setIsAudioDialogOpen}
                isProfileClicked = {isProfileClicked}
                setIsProfileClicked = {setIsProfileClicked}
              />
            
            </>
          )}
        </Grid>
      </Grid>




      <Dialog
          open={twoFADialogOpen}
          PaperProps={{
            style: {
              backgroundColor: globalData.mode === "dark" ? "black" : "white",
              // backgroundColor: "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
            },
          }}
        >
          <DialogTitle
            sx={{
              color:
                globalData.mode === "dark" ? DarkMode.titleAndText : "Black",
              width: "400px",
            }}
          >
            Two factor Authentication
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Enter 2FA Code"
              name="token"
              type="password"
              fullWidth
              value={token}
              onChange={handleTokenChange}
              margin="normal"
              helperText={tokenerror}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleVerifyTokenFA}
              color="primary"
              variant="contained"
              sx={{
                color: globalData.mode === "dark" ? "Black" : "white",
                backgroundColor:
                  globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                "&:focus, &:hover": {
                  backgroundColor:
                    globalData.mode === "dark"
                      ? DarkMode.iconColorOnSelection
                      : "#0085FE",
                  color: globalData.mode === "dark" ? "black" : "White",
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
    </Box>
  );
};

export default MyNotesComponent;
