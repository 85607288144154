import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Divider, Typography, Box, Button, Grid, TextField } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DarkMode from '../../../utils/color/DarkMode';
import { useData } from "../../../context/dataContext";

interface DAPNoteProps {
  dapDataText: string;
  dapAssessmentText: string;
  dapPlanText: string;
  transcriptedText: string;
  audio: string | null;
  // globalData: any;
  handleCopy: (text: string) => void;
  isEditingDapData: boolean;
  handleEditDapDataClick: () => void;
  handleSaveDapDataClick: () => void;
  handleDapDataTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditingDapAssessment: boolean;
  handleEditDapAssessmentClick: () => void;
  handleSaveDapAssessmentClick: () => void;
  handleDapAssessmentTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isEditingDapPlan: boolean;
  handleEditDapPlanClick: () => void;
  handleSaveDapPlanClick: () => void;
  handleDapPlanTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DAPNote: React.FC<DAPNoteProps> = ({
  dapDataText,
  dapAssessmentText,
  dapPlanText,
  transcriptedText,
  audio,
  // globalData,
  handleCopy,
  isEditingDapData,
  handleEditDapDataClick,
  handleSaveDapDataClick,
  handleDapDataTextChange,
  isEditingDapAssessment,
  handleEditDapAssessmentClick,
  handleSaveDapAssessmentClick,
  handleDapAssessmentTextChange,
  isEditingDapPlan,
  handleEditDapPlanClick,
  handleSaveDapPlanClick,
  handleDapPlanTextChange,
}) => {
  const [hoveredSection, setHoveredSection] = useState<string | null>(null);
  const [copiedSection, setCopiedSection] = useState<string | null>(null);
  const { globalData } = useData();

  const handleMouseEnter = (section: string) => {
    setHoveredSection(section);
  };

  const handleMouseLeave = () => {
    setHoveredSection(null);
  };

  const handleCopyClick = (section: string, text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setCopiedSection(section);
        setTimeout(() => setCopiedSection(null), 2000);
      })
      .catch((err) => console.error('Failed to copy text: ', err));
  };

  const textFieldStyles = {
    color: globalData.mode === 'dark'
      ? DarkMode.titleAndText
      : "ffffff",
    '& .MuiInput-underline:before': {
      borderBottom: `1px solid ${globalData.mode === 'dark' ? DarkMode.notesFieldTitleAndTexts : '#000'}`,
    },
    '& .MuiInput-underline:after': {
      borderBottom: `2px solid ${globalData.mode === 'dark' ? DarkMode.notesFieldOnSelectionGlowingBorder : '#000'}`,
    },
    '&:focus': {
      outline: 'none',
    },
    minHeight: 'auto',
  };

  return (
    <div>
      <Box
        sx={{
          maxHeight: "85vh",
          backgroundColor: "#D0E9FF",
          overflowY: "auto",
          scrollbarWidth: 'none', 
          '&::-webkit-scrollbar': {
            display: 'none' 
          }
        }}
      >
        <Grid>
          <Card variant="outlined" sx={{ m: 0.5 }}>
            <CardContent sx={{ paddingTop: 0, paddingBottom: 0,  color: globalData.mode === 'dark' 
                ? DarkMode.titleAndText :"FFFFFF" }}>
              {["DATA", "ASSESSMENT", "PLAN"].map((section) => (
                <div key={section}>
                  <div
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    onMouseEnter={() => handleMouseEnter(section)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Typography variant="h6">{section}</Typography>
                    <Box sx={{ marginRight: "20px" }}>
                      {hoveredSection === section && (
                        <Button onClick={() => handleCopyClick(section, section === "DATA" ? dapDataText : section === "ASSESSMENT" ? dapAssessmentText : dapPlanText)}>
                          {copiedSection === section ? <DoneIcon /> : <ContentPasteIcon />}
                        </Button>
                      )}
                    </Box>
                  </div>
                  <TextField
                    fullWidth
                    multiline
                    variant="standard"
                    value={section === "DATA" ? dapDataText : section === "ASSESSMENT" ? dapAssessmentText : dapPlanText}
                    onChange={section === "DATA" ? handleDapDataTextChange : section === "ASSESSMENT" ? handleDapAssessmentTextChange : handleDapPlanTextChange}
                    sx={textFieldStyles}
                    maxRows={Infinity}
                    minRows={1}
                  />
                  <Divider />
                </div>
              ))}
            </CardContent>
          </Card>
        </Grid>

        <Grid sx={{ m: 2 }}>
          <Card variant="outlined" sx={{ m: -1.2 }}>
            <CardHeader title="Transcripted Text" />
            <CardContent>
              <Typography sx={{
                color: globalData.mode === 'dark'
                  ? DarkMode.notesFieldTitleAndTexts
                  : DarkMode.textInsideInputFieldsDefault,
                mt: -3,
              }}>
                {transcriptedText}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid container sx={{ display: "flex", justifyContent: "space-evenly" }}>
          {audio && (
            <a href={audio} target="_blank" rel="noopener noreferrer">Listen to Audio</a>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default DAPNote;
