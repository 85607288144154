import { Grid, keyframes, styled } from "@mui/material";
import logo from "../images/icon_light-removebg-preview.png"
import React from "react";
const Logo: React.FC = () => {
  const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1;}
`;
  const BlinkedBox = styled(Grid)({
    width: "100%",
    height: "100%",
    animation: `${blink} 4s infinite`,
  });
  return (
    <BlinkedBox
      xs={false}
      md={7}
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "contain",
        backgroundPosition: "center",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
      }}
    >
      <img src={logo} alt="Logo"/>
    </BlinkedBox>
  );
};
export default Logo;
