import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProductAppBar from "../productAppBar/ProductAppBar";
import CategoryMenu from "../userProfile/categoryMenu";
import { AxiosError } from "axios";
import Cookies from "js-cookie";
import {
  Box,
  Button,
  Divider,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
  Paper,
  Snackbar,
  Alert,
  Link,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Cookie, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  fetchActiveSession,
  ssoLogout,
  resetinpage,
  showAlert,
  disablePasskey,
  verifyPassword,
  passkeyInitRegistration,
  passkeyVerifyRegistration,
  generateQRCode,
  checkIf2FAEnabled,
  verifyToken,
  disableTwo2FA,
} from "../../controller/registerController";
import { useData } from "../../context/dataContext";
import DarkMode from "../../utils/color/DarkMode";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { fetchUser, userActions } from "../../store/user";
import { userDetailsActions } from "../../store/userDetails";
import logger from "../../logger/loggerConfig";
import {
  startRegistration,
} from "@simplewebauthn/browser";
import LightMode from '../../utils/color/LightMode';

interface ErrorResponse {
  message: string;
}

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).response !== undefined;
}

const LoginAndSecurity: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [sessions, setSessions] = useState<any[]>([]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [twoFADialogOpen, settwoFADialogOpen] = useState(false);
  const [qrcode, setqrcode] = useState("");
  const [token, settoken] = useState("");
  const [successtwoFADialog, setsuccesstwoFADialog] = useState(false);
  const [twoFAStatus, settwoFAStatus] = useState("");
  const [tokenerror, settokenerror] = useState("");
  const [successPasskeyDialog, setSuccessPasskeyDialog] = useState(false);
  const [successPasskeyDialogMessage, setSuccessPasskeyDialogMessage] =
    useState("");
  const [
    twoFADialogOpenbeforeCreatingPasskey,
    settwoFADialogOpenbeforeCreatingPasskey,
  ] = useState(false);
  const [two2faLink, setTwo2faLink] = useState("");
  const [two2faCode, setTwo2faCode] = useState("");
  const [passwordOpenDialogBCP, setpasswordOpenDialogBCP] = useState(false);
  const [passwordBPC, setpasswordBPC] = useState("");
  const [passworderrorBPC, setpassworderrorBPC] = useState("");
  const [typeofPasskeyCall, setTypeofPasskeyCall] = useState("");
  const [dialogToUpdatePasskey, setdialogToUpdatePasskey] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [user, setUser] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [isChecked,setisChecked]=useState(false);
  const disablePasskeyButtonRef = useRef<HTMLButtonElement>(null);
  const userFromStore = useSelector((store: RootState) => store.user);
  const [shouldRender, setShouldRender] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up(1300));
  const userDetailsFromStore = useSelector(
    (store: RootState) => store.userDetails
  );
  const { globalData } = useData();
  const mode = globalData.mode === 'dark' ? DarkMode : LightMode;
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    logger.info("User Accessed loginAndSecurity Page");
    // console.log(userFromStore);
    checkIfEnabled2FA();
    checkIfUserCanDisablePasskey();
  }, [userFromStore.status]);
  useEffect(() => {
    if (userFromStore.status === "idle") {
      dispatch(fetchUser());
    }
    if (userFromStore.status === "failed") {
      navigate("/");
    }
    if (userFromStore.status === "succeeded") {
      // console.log("google check");
      // console.log(userFromStore.data);
      if (!userFromStore.data?.login) {
        logger.debug("login type", userFromStore.data?.login);
        setShouldRender(true);
      }
      setUser(userFromStore.data);
      fetchSessions(userFromStore.data?.id);
    }
  }, [userFromStore.status, dispatch]);

  const fetchSessions = async (userId: any) => {
    try {
      const data = await fetchActiveSession(userId);
      setSessions(data.session);
      if (data.sessions) {
        setSessions(data.sessions);
      }
      if (data.logintype) {
        logger.debug("this isssss theeeeeeee logintytpeeeeee", data.logintype);
        setLoginType(data.logintype);
      }
    } catch (error) {
      logger.error("Error fetching active sessions:", error);
    }
  };

  const handleLogout = async () => {
    try {
      const result = await ssoLogout();
      logger.debug("Google login result => ", result);
      if (result.status === 200) {
        // localStorage.removeItem("user");
        // localStorage.removeItem("userDetails");
        dispatch(userDetailsActions.deleteUserDetails());
        dispatch(userActions.deleteUser());
        setLoginType("");
        navigate("/"); //S- Redirect to the home page after logout
      }
    } catch (error) {
      logger.error("Error while logging out the user:", error);
    } finally {
      handleCloseDialog();
    }
  };

  const handleCloseDialogToUpdatePasskey = () => {
    setdialogToUpdatePasskey(false);
  };

  const handleProfileClick = () => {
    navigate("/profile");
  };

  const handleMyNotesClick = () => {
    navigate("/notes");
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenPasswordDialog = () => {
    setPasswordDialogOpen(true);
  };

  const handleClosepasswordDialogBCP = () => {
    setpasswordOpenDialogBCP(false);
  };

  const handlepasswordBPCChange = (e: any) => {
    setpasswordBPC(e.target.value);
  };



/**
 * @description Opens the 2FA dialog, fetches the QR code for enabling two-factor authentication (2FA), 
 * and updates the UI with the relevant 2FA data (QR code, link, and code).
 * It sends a request to generate a QR code if the user is enabling 2FA, 
 * otherwise, it only opens the dialog.
 * 
 * @returns {} This function does not return a value 
 */
const handleOpentwoFADialog = async () => {
  // Opens the 2FA dialog
  settwoFADialogOpen(true);
  

  // console.log("I am here");
  // console.log(userFromStore); 
  // console.log(userFromStore.data?.id); 
  // console.log(twoFAStatus);


  const payload = {
    id: userFromStore.data?.id,
  };

  // If 2FA is to be enabled
  if (twoFAStatus === "Enable 2FA") {
      // console.log("I am in if");

      let data = await generateQRCode(payload);
      // console.log(data);

      // Update the state with the fetched QR code and other 2FA-related data
      setqrcode(data.message);
      setTwo2faLink(data.link);
      setTwo2faCode(data.code);
  } else {
      // If 2FA is not enabled, simply exit 
  }
};


/**
 * @description Checks if 2FA (two-factor authentication) is enabled for the current user. 
 * It sends a request to the server and updates the 2FA status accordingly. If 2FA is enabled, 
 * it sets a flag to indicate that 2FA is checked.
 * 
 * 
 * 
 * @returns {void} This function does not return a value.
 */
const checkIfEnabled2FA = async () => {
  // console.log("calling check");

  const payload = {
    id: userFromStore.data?.id,
  };


  // console.log(payload);

  // Send an API request to check the 2FA status for the user


  let data = await checkIf2FAEnabled(payload);
  // console.log(data); 

  if (data.message !== "Enable 2FA") {
    // console.log("I am in checking");
    setisChecked(true);
  }
  settwoFAStatus(data.message);
};




  /**
 * @description Checks if the user can disable their passkey by sending a request to the server. 
 * Based on the response, it either enables or disables the "Disable Passkey" button.
 * 
 * @async
 * @function checkIfUserCanDisablePasskey
 * 
 * @returns {Promise<void>} This function does not return a value but updates the UI by enabling or disabling the button.
 */
const checkIfUserCanDisablePasskey = async () => {
  const payload = {
    email: userFromStore.data?.email,
  };
  const response = await showAlert(payload);
  

  // console.log("Disable button status", response.message);

  // If the response message allows disabling the passkey
  if (response.message) {
    if (disablePasskeyButtonRef.current) {
      // console.log("making enable");
      disablePasskeyButtonRef.current.disabled = false;
    }
  } else {
    if (disablePasskeyButtonRef.current) {
      // console.log("making disable");
      disablePasskeyButtonRef.current.disabled = true;
    }
  }
};


  const handleClosePasswordDialog = () => {
    setPasswordDialogOpen(false);
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setCurrentPasswordError("");
    setPasswordError("");
  };
  const handleClosetwoFADialog = () => {
    // document.getElementById("switch")?.click();
    settwoFADialogOpen(false);
    settoken("");
    settokenerror("");
    console.log("closing 2fa dialog");
  };

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name === "currentPassword") {
      setCurrentPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setConfirmNewPassword(value);
    }

    if (name === "newPassword" && !validatePassword(value)) {
      setPasswordError(
        "Password must be at least 8 characters long and include an uppercase letter, lowercase letter, digit, and special character (! @ # $ & ? _ *."
      );
    } else if (name === "confirmNewPassword" && value !== newPassword) {
      setPasswordError("Passwords do not match.");
    } else {
      setPasswordError("");
    }
  };

  const handlePasswordUpdate = async () => {
    if (!validatePassword(newPassword)) {
      setPasswordError(
        "Password must be at least 8 characters long and include an uppercase letter, lowercase letter, digit, and special character (! @ # $ & ? _ *"
      );
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    try {
      const response = await resetinpage(
        currentPassword,
        newPassword,
        userFromStore.data?.id
      );
      logger.debug("Password updated successfully:", response);
      setSuccessDialogOpen(true);
      setTimeout(() => {
        setSuccessDialogOpen(false);
      }, 5000);

      setSnackbarOpen(true); // Open Snackbar on success
      handleClosePasswordDialog();
    } catch (error) {
      if (isAxiosError(error)) {
        const errorResponse = error.response?.data as ErrorResponse;
        if (
          error.response?.status === 404 &&
          errorResponse?.message ===
            "Your new password cannot be the same as your current password."
        ) {
          setCurrentPasswordError(errorResponse?.message);
        }

        if (
          error.response?.status === 400 &&
          errorResponse?.message === "Current password is incorrect"
        ) {
          setCurrentPasswordError("The current password is incorrect.");
        } else {
          setPasswordError(
            "An error occurred while updating the password. Please try again."
          );
          logger.error("Error updating password:", error);
        }
      } else {
        setPasswordError("An unexpected error occurred.");
        logger.error("Unexpected error:", error);
      }
    }
  };

/**
 * @description Handles enabling or disabling two-factor authentication (2FA) based on the user's input and current 2FA status. 
 * It sends a request to verify the provided token and either enables or disables 2FA accordingly. 
 * 
 * 
 *
 * @returns {void} This function does not return a value 
 */
const handleEnableTwoFA = async () => {
  settokenerror("");
  const payload = {
    id: userFromStore.data?.id,
    token: token,
  };

  let data = await verifyToken(payload);
  // console.log(data); 

  // If token verification is successful
  if (data.message === "true") {
     
      if (twoFAStatus !== "Enable 2FA") {
          // console.log("I am in disable 2fa");
          
      
          const body = {
            id: userFromStore.data?.id,
          };

          // Send a request to disable 2FA
          
          let data = await disableTwo2FA(body);
          // console.log(data);
          logger.info("User has disabled 2FA");
          settwoFAStatus("Enable 2FA");
          settoken("");
          setTwo2faLink("");
          setTwo2faCode("");
          setisChecked(false);
      }

      // If 2FA is currently disabled, enable it
      if (twoFAStatus === "Enable 2FA") {
          logger.info("User has Enabled 2FA");
          settwoFAStatus("Disable 2FA");
          setisChecked(true);
          setqrcode("");
          settoken("");
          setTwo2faLink("");
          setTwo2faCode("");
      }

      // Show success dialog for enabling/disabling 2FA
      setsuccesstwoFADialog(true);
      setTimeout(() => {
        setsuccesstwoFADialog(false);
      }, 5000);

     
      handleClosetwoFADialog();
  } else {
     
      settokenerror("Invalid Code");
  }
};


  const handleTokenChange = (e: any) => {
    settoken(e.target.value);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleClose2FADialog = () =>{
    settwoFADialogOpen(false);
    console.log("closing 2FA Dialog");
  }
  const handleClose2FADialogBCP = () => {
    settoken("");
    settokenerror("");
    settwoFADialogOpenbeforeCreatingPasskey(false);
  };

/**
 * @description Handles user authentication based on the provided authentication type and current 2FA status. 
 * If 2FA is not enabled, it shows the password dialog; otherwise, it opens the 2FA dialog for verification before creating a passkey.
 * 
 * @async
 * @function handleAuthUser
 * 
 * @param {string} type - The type of authentication or passkey operation to be performed.
 * 
 * @returns {void} This function does not return a value but opens either the password or 2FA dialog depending on the user's 2FA status.
 */
const handleAuthUser = async (type: string) => {

  setTypeofPasskeyCall(type);

  // Check if 2FA is disabled and prompt the user to enter a password
  if (twoFAStatus === "Enable 2FA") {
      console.log("show password");
      setpasswordBPC(""); 
      setpasswordOpenDialogBCP(true); // Open the password dialog
  } 
  // If 2FA is enabled, prompt the user to complete the 2FA process
  else {
      console.log("perform 2FA");
      settwoFADialogOpenbeforeCreatingPasskey(true); // Open the 2FA dialog
  }
};

/**
 * @description Verifies the 2FA token provided by the user and performs actions based on the type of passkey operation (enable/disable). 
 * If the token is valid, the function either enables or disables the passkey, updating the UI accordingly.
 * 
 * 
 * @returns {void} This function does not return a value but updates the passkey state, shows success or error messages, 
 * and manages dialogs for enabling/disabling the passkey.
 */
const handleVerifyToken = async () => {
  // console.log("I will do ", typeofPasskeyCall);
  // console.log(token);


  const payload = {
    id: userFromStore.data.id,
    token: token,
  };

  const response = await verifyToken(payload);
  // console.log(response); 

  // If token is valid and the user is trying to enable the passkey
  if (response.message === "true" && typeofPasskeyCall === "enable") {
    // console.log("I will enable passkey in 2FA");
    

    settoken("");
    settokenerror("");
    settwoFADialogOpenbeforeCreatingPasskey(false);

    // Proceed with enabling the passkey and show success alert if needed
    showAlertOrNot();
  } 
  else if (response.message === "true") {
    // console.log("I will disable passkey");


    const payload = {
      email: userFromStore.data.email,
    };

    const response = await disablePasskey(payload);
    // console.log(response);

   
    if (response.message === "success") {
      // Close the 2FA dialog and show success message
      settwoFADialogOpenbeforeCreatingPasskey(false);
      setSuccessPasskeyDialog(true);
      setSuccessPasskeyDialogMessage("PassKey disabled successfully");
      logger.info("User has disabled Passkeys");
      setTimeout(() => {
        setSuccessPasskeyDialog(false);
      }, 5000);

      // Disable the passkey button in the UI
      if (disablePasskeyButtonRef.current) {
        // console.log("making disable");
        disablePasskeyButtonRef.current.disabled = true;
      }
    }
  } 
  else {
   
    settokenerror("Invalid token");
  }
};


/**
 * @description Verifies the user's password to either enable or disable the passkey based on the user's request. 
 * If the password is correct, it proceeds to either enable or disable the passkey and updates the UI accordingly.
 * 
 * 
 * @returns {void} This function does not return a value but updates the UI, manages success/error messages, and performs passkey operations.
 */
const handleVerifyPassword = async () => {

  // console.log("I will do ", typeofPasskeyCall);
  // console.log(userFromStore.data.email);
  // console.log(passwordBPC); 
  // console.log("call API");


  const payload = {
    email: userFromStore.data.email,
    password: passwordBPC,
  };


  const response = await verifyPassword(payload);
  // console.log(response); 

  // If the password is correct and the user is enabling the passkey
  if (response.message && typeofPasskeyCall === "enable") {
    // console.log("I will enable passkeys");
    
 
    setpasswordOpenDialogBCP(false);
    showAlertOrNot();
  } 
  // If the password is correct and the user is disabling the passkey
  else if (response.message) {
    // console.log("I will disable passkey");
    // console.log("call API to disable passkey");

  
    const payload = {
      email: userFromStore.data.email,
    };

  
    const response = await disablePasskey(payload);
    // console.log(response); 

    // If passkey disabling is successful
    if (response.message === "success") {
      logger.info("User has disabled Passkeys");
      setpasswordOpenDialogBCP(false);
      setSuccessPasskeyDialog(true);
      setSuccessPasskeyDialogMessage("PassKey disabled successfully");
      setTimeout(() => {
        setSuccessPasskeyDialog(false);
      }, 5000);
    }

    // console.log("show passkey disable message");


    if (disablePasskeyButtonRef.current) {
      // console.log("making disable");
      disablePasskeyButtonRef.current.disabled = true;
    }
  } 

  else {
    setpassworderrorBPC("Invalid Password");
  }
};

/**
 * @description Determines whether to show an alert to the user based on their email and the server's response.
 * If the server's response indicates that an alert is needed, it opens a dialog to update the passkey; otherwise, it proceeds to register a new passkey.
 * 
 * 
 * @returns {void} This function does not return a value but either opens a dialog or triggers the passkey registration process based on the server's response.
 */
const showAlertOrNot = async () => {
  const email = userFromStore.data.email;

  // Prepare the payload with the user's email
  const payload = {
    email: email,
  };

  // Send a request to the server to check if an alert is needed
  const response = await showAlert(payload);
  // console.log(response); 

  // If the server's response indicates that an alert is needed, open the update passkey dialog
  if (response.message) {
    setdialogToUpdatePasskey(true);
  } 
  // Otherwise, proceed to register a new passkey
  else {
    handleRegisterPasskey();
  }
};


/**
 * @description Initiates the registration process for a new passkey. The function calls the server to get registration options, 
 * starts the WebAuthn registration process, and verifies the registration on the server. If successful, it updates the UI with a success message and enables the disable passkey button.
 * 
 * 
 * @returns {void} This function does not return a value but manages the UI state for passkey registration and displays success messages upon completion.
 */
const handleRegisterPasskey = async () => {

  setdialogToUpdatePasskey(false);

  const email = userFromStore.data.email;

  // Fetch registration options for WebAuthn passkey registration
  const options = await passkeyInitRegistration(email);

  try {
    // Start the WebAuthn registration process with the obtained options
    const registrationJSON = await startRegistration(options);
    // console.log(registrationJSON); 

    // Verify the registration with the server
    const verifyData = await passkeyVerifyRegistration(registrationJSON);

    // If the registration is successful
    if (verifyData.message === "success") {
      setSuccessPasskeyDialog(true);
      setSuccessPasskeyDialogMessage(
        `Passkey ${verifyData.updateOrNew} successfully`
      );

      logger.info("User has Enabled Passkeys");
      setTimeout(() => {
        setSuccessPasskeyDialog(false);
      }, 5000);


      if (disablePasskeyButtonRef.current) {
        disablePasskeyButtonRef.current.disabled = false;
      }
    }
  } catch (e) {
    // Handle potential errors during the registration process
  }
};

  return (
    <>
      <ProductAppBar
        open={open}
        showMenu={true}
        setOpen={setOpen}
      />
      
      <Box sx={{ width:'100%', 
      height: '90.5%',
        backgroundColor: globalData.mode === 'dark' ? "black": "white",
        overflowY: 'scroll',

        "&::-webkit-scrollbar": {
          mt:"1px",
          width: "10px", 
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1", 
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#0085FE", 
          height:"200px",
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#1B5AAD",
          borderRadius: "5px",
        },
      }}
      >
        <Box display="flex">
          <div style={{ width: '250', flexBasis: '250' }}>
          <CategoryMenu open={open} selectedCategory={"PersonalInfo"} />
          </div>
          <Box flexGrow={1} ml={3}>
            <Container maxWidth="md"
            >
              <Paper elevation={3} sx={{ p: 3 , 
                "@media(min-width:1200px) and (max-width:1350px)":{
                  marginLeft:"60px"
                }
              }} >
                <Typography
                  variant="h4"
                  sx={{
                    color:
                      globalData.mode === "dark"
                        ? DarkMode.titleAndText
                        : "#044cae",
                  }}
                  gutterBottom
                >
                  Login and security
                </Typography>
                {shouldRender && (
                  <Box mb={4}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            globalData.mode === "dark"
                              ? DarkMode.titleAndText
                              : "#044cae",
                        }}
                      >
                        Password
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={handleOpenPasswordDialog}
                      >
                        Update password
                      </Button>
                    </Box>
                  </Box>
                )}

                <Box mb={4}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="-18px"
                    marginBottom="-18px"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color:
                          globalData.mode === "dark"
                            ? DarkMode.titleAndText
                            : "#044cae",
                      }}
                    >
                      Two Factor Authentication
                    </Typography>
                    {/* <Button variant="outlined" onClick={handleOpentwoFADialog}>
                      {twoFAStatus}
                    </Button> */}
                    <Switch checked={isChecked} onChange={handleOpentwoFADialog}/>
                  </Box>
                </Box>

                {shouldRender ? (
                  <Box mb={4}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        "@media (max-width: 450px)": {
                          display:"block"
                        }
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            globalData.mode === "dark"
                              ? DarkMode.titleAndText
                              : "#044cae",
                        }}
                      >
                        Passkey
                      </Typography>
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            handleAuthUser("enable");
                          }}
                          sx={{ marginRight: "10px" }}
                        >
                          ADD THIS DEVICE
                        </Button>
                        <Button
                          variant="outlined"
                          ref={disablePasskeyButtonRef}
                          onClick={() => {
                            handleAuthUser("disable");
                          }}
                        >
                          Disable
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <p></p>
                )}

                <Divider />
                <Box mt={4}>
                  <Typography variant="h6" gutterBottom>
                    Device history
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    {loginType}
                  </Typography>
                  <Box mt={1}>
                    {sessions.length > 0 ? (
                      sessions.map((session, index) => (
                        <Box key={index} mb={1}>
                          <Paper elevation={2} sx={{ p:1}}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Box>
                                <Typography
                                  sx={{
                                    color: globalData.mode === 'dark' ? DarkMode.titleAndText : "#0085FE",
                                  }}>{session.deviceInfo}</Typography>
                                <Typography variant="body2" color="#52ADFF">
                                  {session.ip}
                                </Typography>
                                <Typography variant="body2" color="#52ADFF">
                                  {new Date(session.timestamp).toLocaleString()},
                                </Typography>
                              </Box>
                              {/* Button for session status */}
                              <Box
                                sx={{
                                  backgroundColor:
                                    session.active === true
                                      ? "#0085FE"
                                      : "#9c9a9a",
                                  color:
                                    session.active === true ? "white" : "black",
                                  // color:  globalData.mode === 'dark' ? "white": "black",
                                  padding: "8px 16px", // Adjust padding as needed
                                  borderRadius: "4px", // You can adjust the border radius as needed
                                  // border: '2px solid #1990FF',
                                  display: "inline-block",
                                  textAlign: "center",
                                  // cursor: session.active === true ? 'pointer' : 'default',
                                }}
                              >
                                {session.active === true
                                  ? "Logged In"
                                  : "Logged Out"}
                              </Box>
                            </Box>
                          </Paper>
                        </Box>
                      ))
                    ) : (
                      <Typography>No active sessions found.</Typography>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Container>
          </Box>
        </Box>
        <Dialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              backgroundColor: globalData.mode === "dark" ? "black" : "white",
              // backgroundColor: "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
            },
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Confirm Logout
          </DialogTitle>
          <DialogContent>Are you sure you want to log out?</DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                onClick={handleCloseDialog}
                color="primary"
                variant="contained"
                sx={{
                  color: globalData.mode === "dark" ? "Black" : "white",
                  backgroundColor:
                    globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                  "&:focus, &:hover": {
                    backgroundColor:
                      globalData.mode === "dark"
                        ? DarkMode.iconColorOnSelection
                        : "#0085FE",
                    color: globalData.mode === "dark" ? "black" : "White",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleLogout}
                color="primary"
                variant="contained"
                sx={{
                  color: globalData.mode === "dark" ? "Black" : "white",
                  backgroundColor:
                    globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                  "&:focus, &:hover": {
                    backgroundColor:
                      globalData.mode === "dark"
                        ? DarkMode.iconColorOnSelection
                        : "#0085FE",
                    color: globalData.mode === "dark" ? "black" : "White",
                  },
                }}
              >
                Confirm
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Dialog
          open={passwordDialogOpen}
          onClose={handleClosePasswordDialog}
          PaperProps={{
            style: {
              borderRadius: 15,
              border: `2px solid ${mode.DB_BorderLine}`,
              padding: "20px",
              backgroundColor: mode.DB_Background,
            },
          }}
        >
          <DialogTitle 
          sx={{
            color: mode.DB_MainTitle }}
            >Update Password</DialogTitle>
          <DialogContent
          sx={{ color: mode.DB_TextColor }}>
            <TextField
              label="Current Password"
              name="currentPassword"
              type={showCurrentPassword ? "text" : "password"}
              fullWidth
              value={currentPassword}
              onChange={handlePasswordChange}
              margin="normal"
              error={!!currentPasswordError}
              helperText={currentPasswordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      edge="end"
                    >
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="New Password"
              name="newPassword"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              value={newPassword}
              onChange={handlePasswordChange}
              margin="normal"
              error={!!passwordError && !currentPasswordError}
              helperText={
                passwordError && !currentPasswordError ? passwordError : ""
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge="end"
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm New Password"
              name="confirmNewPassword"
              type={showConfirmNewPassword ? "text" : "password"}
              fullWidth
              value={confirmNewPassword}
              onChange={handlePasswordChange}
              margin="normal"
              // error={!!passwordError && !currentPasswordError}
              // helperText={passwordError && !currentPasswordError ? passwordError : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmNewPassword(!showConfirmNewPassword)
                      }
                      edge="end"
                    >
                      {showConfirmNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePasswordDialog} color="primary" variant="contained"
              sx={{
                color:globalData.mode === 'dark' ? "Black": "white",
                backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
                '&:focus, &:hover': {
                  backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
                  color: globalData.mode === 'dark' ?  "black" : "White",
                }}}>
              Cancel
            </Button>
            <Button onClick={handlePasswordUpdate} color="primary" variant="contained"
              sx={{
                color:globalData.mode === 'dark' ? "Black": "white",
                backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
                '&:focus, &:hover': {
                  backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
                  color: globalData.mode === 'dark' ?  "black" : "White",
                }}}>
              Update
            </Button>
          </DialogActions>
        </Dialog>

        {/* 2FA Dialog */}
        <Dialog
          open={twoFADialogOpen}
          onClose={handleClose2FADialog}
          PaperProps={{
            style: {
              backgroundColor: globalData.mode === "dark" ? "black" : "white",
              // backgroundColor: "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
            },
          }}
        >
          <DialogTitle
            sx={{
              color:
                globalData.mode === "dark" ? DarkMode.titleAndText : "Black",
              width: "400px",
            }}
          >
            {twoFAStatus.slice(0, 7)} Two factor Authentication
          </DialogTitle>
          <DialogContent>
            {twoFAStatus==="Enable 2FA" ?
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Box>
                  
                <Box sx={{textAlign:"center"}}>
                <img src={qrcode} alt="" />
                </Box>
                <Typography variant="body1" sx={{ textAlign: "center",color:globalData.mode === "dark" ? "white" : "dark" }}>
                  Unable to scan the code?
                </Typography>
                <Link
                  href={two2faLink}
                  sx={{
                    textAlign: "center",
                    display: "block",
                    fontWeight: "bold",
                  }}
                >
                  Click here
                </Link>
                <Typography
                  variant="body1"
                  sx={{ textAlign: "center", marginTop: 1 , color:globalData.mode === "dark" ? "white" : "dark"}}
                >
                  If the link doesn't work, copy the code below and paste it
                  into your authenticator app:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color:globalData.mode === "dark" ? "white" : "dark"
                  }}
                >
                  {two2faCode}
                </Typography>
              </Box>
            </Box> : <p></p>}
            <TextField
              label="Enter 2FA Code"
              name="token"
              type="password"
              fullWidth
              value={token}
              onChange={handleTokenChange}
              margin="normal"
              helperText={tokenerror}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              inputProps={{
                autoComplete: 'one-time-code'
             }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClosetwoFADialog}
              color="primary"
              variant="contained"
              sx={{
                color: globalData.mode === "dark" ? "Black" : "white",
                backgroundColor:
                  globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                "&:focus, &:hover": {
                  backgroundColor:
                    globalData.mode === "dark"
                      ? DarkMode.iconColorOnSelection
                      : "#0085FE",
                  color: globalData.mode === "dark" ? "black" : "White",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEnableTwoFA}
              color="primary"
              variant="contained"
              sx={{
                color: globalData.mode === "dark" ? "Black" : "white",
                backgroundColor:
                  globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                "&:focus, &:hover": {
                  backgroundColor:
                    globalData.mode === "dark"
                      ? DarkMode.iconColorOnSelection
                      : "#0085FE",
                  color: globalData.mode === "dark" ? "black" : "White",
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* 2fa dialog end */}

        {/* 2fa succes dialog */}

        <Dialog
          open={successtwoFADialog}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              // backgroundColor: "white",
              backgroundColor: globalData.mode === "dark" ? "black" : "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
              boxShadow: "none",
            },
          }}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
              backdropFilter: "none",
            },
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px", // Adjust as needed
              fontWeight: "bold", // Optional, for emphasis
              color:
                globalData.mode === "dark" ? DarkMode.titleAndText : "black",
            }}
          >
            {twoFAStatus === "Enable 2FA"
              ? "Disabled 2FA Successfully !"
              : "Enabled 2FA Successfully !"}
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px", // Ensure content has padding
            }}
          >
            <Typography
              sx={{
                textAlign: "center", // Center the text within the dialog
                fontSize: "16px", // Adjust font size as needed
                color: globalData.mode === "dark" ? "white" : "black",
              }}
            >
              {twoFAStatus === "Enable 2FA"
                ? "Two Factor Authentication has disabled successfully."
                : "Two Factor Authentication has enabled successfully."}
            </Typography>
          </DialogContent>
        </Dialog>

        {/* 2fa success dialog end */}

        {/* ask user to perfom 2FA before creating passkey start */}

        <Dialog
          open={twoFADialogOpenbeforeCreatingPasskey}
          onClose={handleClose2FADialogBCP}
          PaperProps={{
            style: {
              backgroundColor: globalData.mode === "dark" ? "black" : "white",
              // backgroundColor: "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
            },
          }}
        >
          <DialogTitle
            sx={{
              color:
                globalData.mode === "dark" ? DarkMode.titleAndText : "Black",
              width: "400px",
            }}
          >
            Perform Two Factor Authentication Before You Proceed
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Enter 2FA Code"
              name="token"
              type="password"
              fullWidth
              value={token}
              onChange={handleTokenChange}
              margin="normal"
              helperText={tokenerror}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              inputProps={{
                autoComplete: 'one-time-code'
             }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose2FADialogBCP}
              color="primary"
              variant="contained"
              sx={{
                color: globalData.mode === "dark" ? "Black" : "white",
                backgroundColor:
                  globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                "&:focus, &:hover": {
                  backgroundColor:
                    globalData.mode === "dark"
                      ? DarkMode.iconColorOnSelection
                      : "#0085FE",
                  color: globalData.mode === "dark" ? "black" : "White",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleVerifyToken}
              color="primary"
              variant="contained"
              sx={{
                color: globalData.mode === "dark" ? "Black" : "white",
                backgroundColor:
                  globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                "&:focus, &:hover": {
                  backgroundColor:
                    globalData.mode === "dark"
                      ? DarkMode.iconColorOnSelection
                      : "#0085FE",
                  color: globalData.mode === "dark" ? "black" : "White",
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* ask user to perfom 2FA before creating passkey end */}

        {/* ask your to enter password before creating passkey start */}

        <Dialog
          open={passwordOpenDialogBCP}
          onClose={handleClosepasswordDialogBCP}
          PaperProps={{
            style: {
              backgroundColor: globalData.mode === "dark" ? "black" : "white",
              // backgroundColor: "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
            },
          }}
        >
          <DialogTitle
            sx={{
              color:
                globalData.mode === "dark" ? DarkMode.titleAndText : "Black",
              width: "400px",
            }}
          >
            Enter Password Before You Proceed
          </DialogTitle>
          <DialogContent>
            <TextField
              label="Enter password"
              name="pass"
              type="password"
              fullWidth
              value={passwordBPC}
              onChange={handlepasswordBPCChange}
              margin="normal"
              helperText={passworderrorBPC}
              FormHelperTextProps={{
                style: { color: "red" },
              }}
              inputProps={{
                autoComplete: 'off'
             }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClosepasswordDialogBCP}
              color="primary"
              variant="contained"
              sx={{
                color: globalData.mode === "dark" ? "Black" : "white",
                backgroundColor:
                  globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                "&:focus, &:hover": {
                  backgroundColor:
                    globalData.mode === "dark"
                      ? DarkMode.iconColorOnSelection
                      : "#0085FE",
                  color: globalData.mode === "dark" ? "black" : "White",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleVerifyPassword}
              color="primary"
              variant="contained"
              sx={{
                color: globalData.mode === "dark" ? "Black" : "white",
                backgroundColor:
                  globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                "&:focus, &:hover": {
                  backgroundColor:
                    globalData.mode === "dark"
                      ? DarkMode.iconColorOnSelection
                      : "#0085FE",
                  color: globalData.mode === "dark" ? "black" : "White",
                },
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* ask your to enter password before creating passkey end */}

        {/* passkey success dialog */}

        <Dialog
          open={successPasskeyDialog}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              // backgroundColor: "white",
              backgroundColor: globalData.mode === "dark" ? "black" : "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
              boxShadow: "none",
            },
          }}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
              backdropFilter: "none",
            },
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px", // Adjust as needed
              fontWeight: "bold", // Optional, for emphasis
              color:
                globalData.mode === "dark" ? DarkMode.titleAndText : "black",
            }}
          >
            Passkey Status !
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "20px", // Ensure content has padding
            }}
          >
            <Typography
              sx={{
                textAlign: "center", // Center the text within the dialog
                fontSize: "16px", // Adjust font size as needed
                color: globalData.mode === "dark" ? "white" : "black",
              }}
            >
              {successPasskeyDialogMessage}
            </Typography>
          </DialogContent>
        </Dialog>

        {/* passkey success dialog end */}

        {/* Alert for user */}

        <Dialog
          open={dialogToUpdatePasskey}
          onClose={handleCloseDialogToUpdatePasskey}
          PaperProps={{
            style: {
              backgroundColor: globalData.mode === "dark" ? "black" : "white",
              // backgroundColor: "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
            },
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Your Existing Passkeys will be deleted !
          </DialogTitle>
          <DialogContent sx={{ textAlign: "center" }}>
            Are you sure you want to create new passkey ? 
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Button
                onClick={handleCloseDialogToUpdatePasskey}
                color="primary"
                variant="contained"
                sx={{
                  color: globalData.mode === "dark" ? "Black" : "white",
                  backgroundColor:
                    globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                  "&:focus, &:hover": {
                    backgroundColor:
                      globalData.mode === "dark"
                        ? DarkMode.iconColorOnSelection
                        : "#0085FE",
                    color: globalData.mode === "dark" ? "black" : "White",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleRegisterPasskey}
                color="primary"
                variant="contained"
                sx={{
                  color: globalData.mode === "dark" ? "Black" : "white",
                  backgroundColor:
                    globalData.mode === "dark" ? DarkMode.iconColor : "#0085FE",
                  "&:focus, &:hover": {
                    backgroundColor:
                      globalData.mode === "dark"
                        ? DarkMode.iconColorOnSelection
                        : "#0085FE",
                    color: globalData.mode === "dark" ? "black" : "White",
                  },
                }}
              >
                Confirm
              </Button>
            </Box>
          </DialogActions>
        </Dialog>

        {/* Alert for user end */}

        {/* Success Dialog */}
        <Dialog
          open={successDialogOpen}
          onClose={handleCloseDialog}
          PaperProps={{
            style: {
              borderRadius: 15,
              border: `2px solid ${mode.DB_BorderLine}`,
              padding: "20px",
              backgroundColor: mode.DB_Background,
            },
          }}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
              backdropFilter: "none",
            },
          }}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px", // Adjust as needed
              fontWeight: "bold", // Optional, for emphasis
              color: mode.DB_MainTitle ,
            }} 
          >
            Password Updated
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color:mode.DB_TextColor,
              padding: "20px", // Ensure content has padding
            }}
          >
            <Typography
              sx={{
                textAlign: "center", // Center the text within the dialog
                fontSize: "16px", // Adjust font size as needed
                color:mode.DB_TextColor,
              }}
            >
              Your password has been successfully updated.
            </Typography>
          </DialogContent>
        </Dialog>

        {/* <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000} // Auto-hide after 5 seconds
          onClose={handleCloseSnackbar}
          message="Password updated successfully!"
        >
          <Alert onClose={handleCloseSnackbar} severity="success">
            Password updated successfully!
          </Alert>
        </Snackbar> */}
      </Box>
    </>
  );
};

export default LoginAndSecurity;
