 import logger from "../logger/loggerConfig";
const getApiUrl = (baseUrl:any): string => {
    logger.debug(baseUrl);
    switch (baseUrl) {
      case "dev":
        return "https://dev.qurenote.com";
        break;
      case "prod":
        return "https://qurenote.com";
        break;
      case "test":
        return "https://test.qurenote.com";
        break;
      case "cert":
        return "https://cert.qurenote.com";
        break;
      default:
        return "http://localhost:8000";
    }
};

export default  getApiUrl;
