import axios, { AxiosError } from "axios";
import CryptoJS from "crypto-js";
import { getDataFromLocalStorage, saveDataToLocalStorage } from "../localStorageComp/storage";
import { Password } from "@mui/icons-material";
import getApiUrl from "../components/config";
import { getDeviceType } from "../components/DeviceInfo";
import logger from "../logger/loggerConfig";


const SERVER_URL = getApiUrl(process.env.REACT_APP_URL);




export const registerUser = async (userData: any) => {
  try {
    // Convert the object to a JSON string
    const jsonString = JSON.stringify(userData);

    // Ensure proper UTF-8 encoding for the data
    const utf8Data = CryptoJS.enc.Utf8.parse(jsonString);

    // Use a secret key for encryption (replace 'your-secret-key' with an actual secret key)
    const secretKey = "your-secret-key";

    // Encrypt the data
    const encrypted = CryptoJS.AES.encrypt(utf8Data, secretKey).toString();

    const response = await axios.post(`${SERVER_URL}/api/user/signup`, {
      encrypted: encrypted,
    },{withCredentials:true});
    // const response = await axios.post(`${SERVER_URL}/user/signup`, userData);

    return response.data;
  } catch (error) {
    // Handle error (e.g., show a notification or log it)
    logger.error("Error registering user:", error);
    throw error;
  }
};

// fetch active session
export const fetchActiveSession = async (userId:any) => {
  try {
    const response = await axios.get(`${SERVER_URL}/api/auth/fetchSession/${userId}`,{withCredentials:true});
    return response.data; // Return the session data
  } catch (error) {
    logger.error('Error fetching active sessions:', error);
    throw error;
  }
};

// Manual login
export const loginUser = async (userData: any) => {
  try {
    // Convert the object to a JSON string
    const jsonString = JSON.stringify(userData);

    // Ensure proper UTF-8 encoding for the data
    const utf8Data = CryptoJS.enc.Utf8.parse(jsonString);

    // Use a secret key for encryption (replace 'your-secret-key' with an actual secret key)
    const secretKey = "your-secret-key";

    // Encrypt the data
    const encrypted = CryptoJS.AES.encrypt(utf8Data, secretKey).toString();
    logger.info(SERVER_URL);
    logger.info(process.env.REACT_APP_URL);
    const response = await axios.post(`${SERVER_URL}/api/user/signin`, {
      encrypted: encrypted,
    },{withCredentials:true});
    return response.data;
  } catch (error : unknown) {
    // Check if the error is an AxiosError
    if(error && (error as AxiosError).response){
      const axiosError = error as AxiosError;
      logger.error("Error logging in user:", axiosError);
      return axiosError?.response?.data;
    }else {
      // Handle other types of errors
      logger.error("Error logging in user:", error);
      return null;
    }
  }
};

let headers: any = new Headers();

headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
headers.append('Origin','http://localhost:3000');

// google login
export const googleLogin = async () => {
  try {
    window.open(`${SERVER_URL}/api/auth/google/`, "_self");
  } catch (error) {
    // Handle error (e.g., show a notification or log it)
    logger.error("Error during Google login:", error);
    throw error;
  }
};



// facebook login
export const facebookLogin = async () => {
  try {
    window.open(`${SERVER_URL}/api/auth/facebook/`, "_self");
  } catch (error) {
    // Handle error (e.g., show a notification or log it)
    logger.error("Error during Google login:", error);
    throw error;
  }
};


//logout
export const ssoLogout = async () => {
  try {
    const response = await axios.post(`${SERVER_URL}/api/auth/logout`,{},{withCredentials:true});
    // window.open(`${SERVER_URL}/api/auth/logout`, "_self");
    return response;
  } catch (error) {
    // Handle error (e.g., show a notification or log it)
    logger.error("Error during Google login:", error);
    throw error;
  }
};

// logout functionality
export const logout = async () => {
  try {
    window.open(`${SERVER_URL}/api/auth/logout`, "_self");
  } catch (error) {
    // Handle error (e.g., show a notification or log it)
    logger.error("Error during Google login:", error);
    throw error;
  }
};



// transcribe api call
export const transcribeCall = async (formData: any) => {
  try {
    const response = await axios.post(`${SERVER_URL}/api/transcribe`, formData,{withCredentials:true});

    return response.data;
  } catch (error) {
    logger.error("Error transcribing audio:", error);
  }
};

// save soap not  api call
export const soapNoteCall = async (soapNote: any) => {
  try {
    // Make an API request to the server to save the SOAP note
    const response = await axios.post(`${SERVER_URL}/api/soapNote`, soapNote,{withCredentials:true});

    return response.data;
  } catch (error) {
    logger.error("Error transcribing audio:", error);
  }
};

// save soap not  api call
export const soapNoteHistoryCall = async (data: any) => {
  try {
    // Make an API request to the server to save the SOAP note
    const response = await axios.post(`${SERVER_URL}/api/soapNote/history`, {
      data,
    },{withCredentials : true});

    return response.data;
  } catch (error) {
    logger.error("Error transcribing audio:", error);
  }
};
export const fetchDeviceAndIPInfo = async () => {
  // Extract the user agent
  const userAgent = navigator.userAgent;

  let deviceBrand = getDeviceType();
  let deviceModel = ' ';
  try {
    const ipResponse = await fetch("https://api.ipify.org/?format=json");
    if (!ipResponse.ok) {
      throw new Error(
        `Error fetching IP: ${ipResponse.status} ${ipResponse.statusText}`
      );
    }
    // Parse the IP address from the response
    const ipData = await ipResponse.json();
    const ipAddress = ipData.ip;
    logger.info("Fetched IP Address:", ipAddress);

    // Use the fetched IP address with ipinfo.io API
    const apiUrl = `https://ipinfo.io/${ipAddress}/json`;
    const apiInfoResponse = await fetch(apiUrl);
    if (!apiInfoResponse.ok) {
      throw new Error(
        `Error fetching API info: ${apiInfoResponse.status} ${apiInfoResponse.statusText}`
      );
    }

    // Parse the API response
    const apiInfoData = await apiInfoResponse.json();
    const city = apiInfoData.city || "Unknown";
    const state = apiInfoData.region || "Unknown";
    const country = apiInfoData.country || "Unknown"; // Un-comment if needed

    return {
      deviceBrand,
      deviceModel,
      city,
      state,
      // country, // Un-comment if needed
    };
  } catch (error) {
    logger.error("Error fetching device or location information:", error);
    return {
      deviceBrand: "Device login",
      deviceModel: " ",
      city: "NA",
      state: "NA",
      // country: "Unknown", // Un-comment if needed
    };
  }
};


// get soapnote details call
export const soapNotedetails = async (id: any) => {
  try {
    // Make an API request to the server to save the SOAP note
    const response = await axios.post(`${SERVER_URL}/api/soapNote/details`, {
      id,
    },{withCredentials : true});

    return response.data;
  } catch (error) {
    logger.error("Error transcribing audio:", error);
  }
};


// deleteSoapNote
export const deleteNote = async (id: any) => {
  try {
    // Make an API request to the server to delete the SOAP note
    const response = await axios.post(`${SERVER_URL}/api/soapNote/delete`, {
      id,
    },{withCredentials : true});

    return response.data;
  } catch (error) {
    logger.error("Error transcribing audio:", error);
  }
};

// change note soap note to any other 
export const changeNote = async (
  noteId: string, transcriptedText: string,
   noteType: string) => {
  try {
    const body = {noteId, noteType, transcriptedText};
    const response = await axios.post(`${SERVER_URL}/api/soapNote/change
 `, body, { withCredentials: true });

    return response.data;
  } catch (error) {
    console.error("Error updating note details:", error);
  }
};

// reset from Login security
export const resetinpage = async (currentpassword:any,password: any, id: any) => {
  try {
    const response = await axios.post(`${SERVER_URL}/api/userPassword/page-reset`, { currentpassword,password, id },{withCredentials : true});
    
  } catch (error) {
    logger.error('Error resetting password:', error);
    throw error;
  }
};
// send reset password email

export const changepassword = async (email: any) => {
  try {
    // await setEmail(email);
    // localStorage.setItem("email",email);
    const response = await axios.post(
      `${SERVER_URL}/api/userPassword/request-reset`,
      { email },{withCredentials : true}
    );
    // const response = await axios.post(`${SERVER_URL}/user/signup`, userData);

    return response.data;
  } catch (error) {
    logger.error("Error finding user email:", error);
    throw error;
  }
};

export const resetpassword = async (url: any, password: any) => {
  try {
    // const val = userEmail;
    const response = await axios.post(`${SERVER_URL}/api/userPassword/reset`, {
      url,
      password,
    },{withCredentials : true});

    return response.data;
  } catch (error) {
    logger.error("Error finding user email:", error);
    throw error;
  }
};

// Save user details- data to the userDetail table

export const saveUserDetails = async (userId: string, userData: any) => {
  try {
    const response = await axios.post(`${SERVER_URL}/api/userDetails/${userId}`, {
      ...userData,
    },{withCredentials : true});
    return response.data;
  } catch (error) {
    if (error && (error as AxiosError).response) {
      const axiosError = error as AxiosError;
      return axiosError?.response?.data;
    } else {
      logger.error("Error saving user details:", error);
      throw error;
    }
  }
};


// Get user details- data from the userDetail table
export const getUserDetails = async (userId: string) => {
  try {

    

    const response = await axios.get(`${SERVER_URL}/api/userDetails/${userId}`,{withCredentials : true})

    return response.data;
  } catch (error) {
    // Handle error (e.g., show a notification or log it)
    logger.error("Error getting user details:", error);
    throw error;
  }
};


export const fetchAndStoreUserDetails = async (userId: string) => {
  try {
    const response = await axios.get(`${SERVER_URL}/api/userDetails/${userId}`,{withCredentials : true});
    if (response.data.status === "SUCCESS") {
      const { user, userDetails } = response.data.data;
      const userData = { ...user, userDetails };
      // saveDataToLocalStorage("userDetails", { userDetails });
      // const localUserData = await getDataFromLocalStorage("user");
      // localUserData.isSubscribed = user?.isSubscribed; // when user redirects to /profile after payment then this line will update the user in localstorage
      // saveDataToLocalStorage("user", localUserData);
      return userData;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    logger.error("Error fetching user details:", error);
    throw error;
  }
};
export const fetchIP = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json',{withCredentials : true});
    return response.data.ip;
  } catch (error) {
    logger.error('Error fetching IP address:', error);
    return 'Unknown';
  }
};

export const showAlert = async (payload:object)=>{
  try{
   const response = await axios.post(`${SERVER_URL}/api/passkey/show-alert`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials:true
    });
    return response.data;
  }
  catch(e){
    return "error in showAlert"
  }
}

export const disablePasskey = async (payload:object)=>{
  try{
    const response = await axios.post(`${SERVER_URL}/api/passkey/disable-passkey`,payload,{
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials : true
      }
    );
    return response.data;
  }
  catch(e){
    return "error in disablePasskey";
  }
}

export const verifyPassword = async (payload:object)=>{
  try{
    const response = await axios.post(`${SERVER_URL}/api/passkey/verify-password`,payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials : true
      }
    );
    return response.data;
  }
  catch(e){
    return "error in verifyPassword";
  }
}

export const passkeyInitRegistration = async (email:string)=>{
  try{
      const initResponse = await fetch(`${SERVER_URL}/api/passkey/init-register?email=${email}`,   //Here I am error if i use axios
      {
        credentials: "include",
      }
    );
    const options = await initResponse.json();
    // console.log(options);
    return options;
  }
  catch(e){
    return "error in passkeyInitRegistration";
  }
}

export const passkeyVerifyRegistration = async (registrationJSON:object)=>{
  try{
    const verifyResponse = await fetch(`${SERVER_URL}/api/passkey/verify-register`, { //Here I am error if i use axios
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registrationJSON),
    });
    const verifyData = await verifyResponse.json();
    return verifyData;
  }
  catch(e){
    return "error in passkeyVerifyRegistration";
  }
}

export const passkeyInitAuth = async (email:string) =>{
  try{
      const initResponse = await fetch(`${SERVER_URL}/api/passkey/init-auth?email=${email}`,{ //Here I am error if i use axios
      credentials: 'include'
    });
    const options = await initResponse.json();
    return options;
  }
  catch(e){
    return "error in passkeyInitAuth";
  }
}

export const passkeyVerifyAuth = async(authJSON:object)=>{
  try{
    const verifyResponse = await fetch(`${SERVER_URL}/api/passkey/verify-auth`,{  //Here I am error if i use axios
      credentials:"include",
      method:"POST",
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify(authJSON)
    });
    const verifyData = await verifyResponse.json();
    return verifyData;
  }
  catch{
    return "error in passkeyVerifyAuth";
  }
}

export const passkeyLogin = async (payload:object) =>{
  try{
    const result = await axios.post(`${SERVER_URL}/api/passkey/passkey-login`,payload,{
      headers:{
        'Content-Type':"application/json"
      },
      withCredentials:true
    })
    return result.data;
  }
  catch(e){
    return "error in passkeyLogin";
  }
}

export const generateQRCode = async (payload:object)=>{
  try{
    let response = await axios.post(`${SERVER_URL}/api/twofa/qrcode`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
  });
  return response.data;
  }
  catch(e){
    return "error in generate QRCode";
  }
}

export const checkIf2FAEnabled = async (payload:object)=>{
  try{
    let response = await axios.post(`${SERVER_URL}/api/twofa/check2fa`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  }
  catch(e){
    return "error in checkIf2FAEnabled";
  }
}

export const verifyToken = async (payload:object)=>{
  try{
    const result = await axios.post(`${SERVER_URL}/api/twofa/verifytoken`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    });
    return result.data;
  }
  catch(e){
    return "error in verifytoken";
  }
}

export const disableTwo2FA = async (body:object)=>{
  try{
    let response = await axios.post(`${SERVER_URL}/api/twofa/disable2fa`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  }
  catch(e){
  }
}
export const openCustomerPortal = async()=>{
  try{
    const reponse = await axios.get(`${SERVER_URL}/api/customer`,{withCredentials : true});
    return reponse.data;
  }catch(error){
    console.log("error in opening customer portal",error);
  }
}