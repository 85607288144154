import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from '@mui/icons-material/Delete';
import { convertToRawFormat, parseBillingCodes } from "../services/soapNoteServices";
import { useData } from "../context/dataContext";
import DarkMode from "../utils/color/DarkMode";
import logger from "../logger/loggerConfig";

interface BillingCode {
  description: string;
  cpt: string;
  icd10: string;
}
interface BillingCodesTableProps {
    setIsEditing? : any;
    billingCodesText : string;
    setBillingCodesData : any ;
    submitSoapNote : any;
    billingCodes : BillingCode[] | null;
    setBillingCodes : any;
    isBillingCodeEdited : boolean;
    setIsBillingCodeEdited : any;
}

const BillingCodesTable: React.FC<BillingCodesTableProps> = ( { setIsEditing, billingCodesText, setBillingCodesData, submitSoapNote, billingCodes, setBillingCodes, setIsBillingCodeEdited,isBillingCodeEdited} ) => {
  const [copied, setCopied] = useState(false);
  const [editableCell, setEditableCell] = useState<{ row: number, field: keyof BillingCode } | null>(null);
  const [isRowDeleted,setIsRowDeleted] = useState(false);
  const { globalData } = useData();
  const [selected, setSelected] = useState(false);
  useEffect(()=>{
    logger.info("User Accessed BillingCodesTable")
  },[]);
  useEffect(()=>{
    if (isRowDeleted) {
      submitSoapNote();
      setIsRowDeleted(false);
    }
  },[billingCodes]);

  const handleCopy = async () => {
    logger.info("User Copied BillingCode")
    if(!billingCodes)return;
    const tableText = await convertToRawFormat(billingCodes);
    if(!tableText)return;
    navigator.clipboard.writeText(tableText).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 5000); // Reset icon after 5 seconds
    });
  };

  const handleCellChange = async (index: number, field: keyof BillingCode, value: string) => {
    if(!isBillingCodeEdited){
      setIsBillingCodeEdited(true);
    }
    if(!billingCodes)return;
      const updatedBillingCodes = billingCodes.map((code, i) =>
        i === index ? { ...code, [field]: value } : code
      );
      setBillingCodes(updatedBillingCodes);
      // setBillingCodesData(await convertToRawFormat(updatedBillingCodes));
    };
  
  const handleCellClick = (row: number, field: keyof BillingCode) => {
    setIsEditing(true);
    setEditableCell({ row, field });
  };

  const handleBlur = () => {
    setEditableCell(null);
  };

  const handleAddRow = async () => {
    if(!isBillingCodeEdited){
      setIsBillingCodeEdited(true);
    }
    logger.info("User Added Row in Billing Code")
    if(billingCodes){
        const updatedBillingCodes = [...billingCodes, { description: '', cpt: '', icd10: '' }];
        setBillingCodes(updatedBillingCodes);
        // setBillingCodesData(await convertToRawFormat(updatedBillingCodes));
    }else{
        setBillingCodes([{ description: '', cpt: '', icd10: '' }]);
        // setBillingCodesData(await convertToRawFormat([{ description: '', cpt: '', icd10: '' }]));
    }
    setIsEditing(true);
  };

  const handleRemoveRow = async (index: number) => {
    if (!billingCodes) return;
    const updatedBillingCodes = billingCodes.filter((_, i) => i !== index);
    setBillingCodes(updatedBillingCodes);
    // setBillingCodesData(await convertToRawFormat(updatedBillingCodes));
    setIsRowDeleted(true);
    // submitSoapNote();
    setIsEditing(true);
  };

  return (
    <Box sx={{ padding: 2, backgroundColor: 
    globalData.mode === 'dark' ?'black':'white'
    }}>
      <Box display="flex" justifyContent="flex-end" marginBottom={2}>
        <IconButton onClick={handleCopy}>
          {copied ? <DoneIcon /> : <ContentCopyIcon />}
        </IconButton>
      </Box>
      <TableContainer component={Paper} sx={{ border: "2px solid #0085FE" }}>
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: globalData.mode === 'dark' ? '#004F93' : "#81C3FF" }}>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: "2px solid #0085FE",
                  borderBottom: "2px solid #0085FE",
                }}
                align="center"
              >
                Description
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "2px solid #0085FE",
                  borderBottom: "2px solid #0085FE",
                }}
                align="center"
              >
                CPT
              </TableCell>
              <TableCell
                sx={{ borderRight: "2px solid #0085FE", borderBottom: "2px solid #0085FE" }}
                align="center"
              >
                ICD-10
              </TableCell>
              <TableCell
                sx={{ borderBottom: "2px solid #0085FE" }}
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billingCodes &&
              billingCodes.map((code, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: globalData.mode === 'dark'
                      ? (index % 2 === 0 ? "#0D0D0D" : "#002342") // Dark mode row colors
                      : (index % 2 === 0 ? "#FFFFFF" : "#D7ECFF"), // Light mode row colors
                    color: globalData.mode === 'dark' ? "#FFFFFF" : "inherit", // Text color
                    borderBottom: index !== billingCodes.length - 1
                      ? "2px solid #0085FE"
                      : "unset", // Border bottom
                  }}
                >
                  <TableCell
                    sx={{ borderRight: "2px solid #0085FE" }}
                    onClick={() => handleCellClick(index, "description")}
                  >
                    {editableCell?.row === index &&
                    editableCell.field === "description" ? (
                      <TextField
                        fullWidth
                        variant="standard"
                        value={code.description}
                        onChange={(e) =>
                          handleCellChange(index, "description", e.target.value)
                        }
                        onBlur={handleBlur}
                        autoFocus
                      />
                    ) : (
                      code.description
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "2px solid #0085FE" }}
                    align="center"
                    onClick={() => handleCellClick(index, "cpt")}
                  >
                    {editableCell?.row === index &&
                    editableCell.field === "cpt" ? (
                      <TextField
                        fullWidth
                        variant="standard"
                        value={code.cpt}
                        onChange={(e) =>
                          handleCellChange(index, "cpt", e.target.value)
                        }
                        onBlur={handleBlur}
                        autoFocus
                       />
                    ) : (
                      code.cpt
                    )}
                  </TableCell>
                  <TableCell
                    sx={{ borderRight: "2px solid #0085FE" }}
                    align="center"
                    onClick={() => handleCellClick(index, "icd10")}
                  >
                    {editableCell?.row === index &&
                    editableCell.field === "icd10" ? (
                      <TextField
                        fullWidth
                        variant="standard"
                        value={code.icd10}
                        onChange={(e) =>
                          handleCellChange(index, "icd10", e.target.value)
                        }
                        onBlur={handleBlur}
                        autoFocus
                      />
                    ) : (
                      code.icd10
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                  >
                    <IconButton onClick={() => handleRemoveRow(index)}>
                      <DeleteIcon 
                      sx={{
                        color: globalData.mode === 'dark'
                          ? (selected ? DarkMode.iconColorOnSelection : DarkMode.iconColor) // Dark mode colors
                          : (selected ? DarkMode.iconColorOnSelection : "inherit"), // Light mode colors, or default
                        '&:hover': {
                          color: globalData.mode === 'dark' ? "red" : "red", // Hover color for both modes
                        },
                      }}/>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button variant="contained" onClick={handleAddRow}>
          Add Row
        </Button>
      </Box>
    </Box>
  );
};

export default BillingCodesTable;
