import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  facebookLogin,
  googleLogin,
  loginUser,
  fetchDeviceAndIPInfo,
  fetchIP,
  passkeyInitAuth,
  passkeyVerifyAuth,
  passkeyLogin,
  checkIf2FAEnabled,
  verifyToken
} from "../../controller/registerController";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  saveDataToLocalStorage,
} from "../../localStorageComp/storage";
import UAParser from 'ua-parser-js';
import {
  Box,
  Button,
  ButtonGroup,
  CssBaseline,
  FormGroup,
  Grid,
  Paper,
  Typography,
  Link as Link2,
  Divider,
  Container,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  FormControl,
  OutlinedInput,
  TextField,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import LogoComponent from "../logo/logoComponent";
import SignUpAndLogin from "../signUpAndLoginFields/signUpAndLogin";
import ProductAppBar from "../productAppBar/ProductAppBar";
import DarkMode from "../../utils/color/DarkMode";
import { useData } from "../../context/dataContext";
import getApiUrl from "../../components/config";
import { fetchUser, userActions } from "../../store/user";
import { AppDispatch, RootState } from "../../store";
import Cookies from "js-cookie";
import logger from "../../logger/loggerConfig";
import { startAuthentication } from "@simplewebauthn/browser";


const LoginAccount: React.FC = () => {
  const navigate = useNavigate();
  const userFromStore = useSelector((store : RootState)=>store.user);
  const data: any = userFromStore.data;
  const userEmail: any = data?.email;

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{10}$/;

  const [contact, setContact] = useState<string>("");
  const [isContactValid, setIsContactValid] = useState(true);
  const [password, setPassword] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [contactType, setContactType] = useState<string>("");
  const [emailVerfied, setEmailVerified] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogOfPasskey,setdialogOfPasskey]=useState(false);
  const [dialogOf2FA,setDialogof2FA]=useState(false);
  const [email,setemail]=useState("");
  const [token,setToken]=useState();
  const [tokenError,setTokenError]=useState("");
  const [passkeydialogOpen,setpasskeydialogOpen]=useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { globalData } = useData();
  const dispatch = useDispatch<AppDispatch>()
  const SERVER_URL = getApiUrl(process.env.REACT_APP_URL);
  useEffect(() => {
    if (userEmail !== null || userEmail !== undefined) {
      validateContactInput(userEmail);
      setContact(userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    logger.debug("user store from login component",userFromStore.data);
    if(userFromStore.status === 'idle'){
      fetchUser();
    }

    else if (userFromStore.status === 'succeeded' && userFromStore.data?.id) {
      if(!userFromStore.data?.isSubscribed){
        navigate("/subscription");
      }
      else if(userFromStore.data?.isSubscribed){
        checkIfUserHasEnable2FA();
      }
    }
    const isLogIn = Cookies.get('isLogIn');
    logger.debug("token in logincomponent",isLogIn);
    if(isLogIn){
      // console.log("I am here");
      // navigate("/notes");
    }
  }, [navigate, userFromStore.status]);


  const checkIfUserHasEnable2FA = async()=>{
    // console.log("I am calling");
    const payload={
      id:userFromStore.data?.id,
    }
  
    let data = await checkIf2FAEnabled(payload);
    // console.log(data);

    if(data.message==="Enable 2FA"){
      navigate("/notes");
    }
    else{
      // console.log("Two factor enabled");
      setDialogof2FA(true);
    }
  }

  useEffect(() => {
    const currentURL = window.location.search;
    const urlParams = new URLSearchParams(currentURL);
    if (
      urlParams.has("error") &&
      urlParams.get("error") === "usernotregistered"
    ) {
      setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 3000);
    } else {
      setShowErrorMessage(false);
    }
  }, []);

  const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setContact(newValue);
    validateContactInput(newValue);
  };

  const validateContactInput = (input: any) => {
    if (
      input?.trim() === "" ||
      (!emailRegex.test(input) && !phoneRegex.test(input))
    ) {
      setIsContactValid(false);
      // setErrorMessage("Please enter a valid email.");
    } else {
      setIsContactValid(true);
      setErrorMessage("");
    }

    if (emailRegex.test(input)) {
      setContactType("email");
    } else if (phoneRegex.test(input)) {
      setContactType("phoneNumber");
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (inputPassword: string) => {
    const minLength = 8;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /\d/;
    const spaceRegex = /\s/;
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{}|;':",./<>?]/;

    const isValidPassword =
      inputPassword.length >= minLength &&
      uppercaseRegex.test(inputPassword) &&
      lowercaseRegex.test(inputPassword) &&
      digitRegex.test(inputPassword) &&
      !spaceRegex.test(inputPassword) &&
      specialCharRegex.test(inputPassword);

    setIsPasswordValid(isValidPassword);
  };
  

  const handleSubmit = async () => {
    if(!dialogOf2FA){
      setDialogof2FA(true);
      return;
    }
    try {
      
      if (
        contact !== "" &&
        password !== "" &&
        (contactType === "email" || contactType === "phoneNumber")
      ) {
        // if (!emailRegex.test(contact)) {
        //   setErrorMessage("Please enter a valid email.");
        // } else {
        //   setErrorMessage("");
        // }
        if (
          (emailRegex.test(contact) || phoneRegex.test(contact)) &&
          password.length >= 8
        ) {
          setErrorMessage("");
          const deviceInfo = await fetchDeviceAndIPInfo();

          if (!deviceInfo) {
            logger.error("Failed to retrieve device and IP info");
            return;
          }

          // Now you can use deviceInfo for further operations
          logger.info("Device Info:", deviceInfo);
          const result = await loginUser({
            contact,
            password,
            contactType,
            deviceBrand: deviceInfo.deviceBrand, // Use deviceBrand instead of deviceId
            deviceModel: deviceInfo.deviceModel, // Assuming you want to include deviceModel as well
            city: deviceInfo.city,
            state: deviceInfo.state
          });
          // console.log(result);
          // Handle successful user registration, if needed
          if (result.status === "SUCCESS") {
            // console.log("calling");
            const userVal = result.data.user;
            
            const resultToken = result.token;
            dispatch(userActions.setUser(userVal));
            // userVal["token"] = resultToken;
            // saveDataToLocalStorage("user", {...userVal, token : resultToken});
            if (result?.data.user.isSubscribed) {
              // console.log("I am here");
              // navigate("/notes");
            } else {
              // console.log("I am here")
              navigate("/subscription");
            }
          } else {
            let message = "";
            if (
              result.status === "FAILED" &&
              result?.info?.message ===
                "Email hasn't been verified yet. Check your inbox."
            ) {
              setEmailVerified(false);
            } else if (
              result.status === "FAILED" &&
              result?.info?.message === "Please enter a Valid email!"
            ) {
              message = "User not registered!";
            } else if (
              result.status === "FAILED" &&
              result?.info?.message === "Invalid Credentials!"
            ) {
              message = "You have entered wrong password!";
            } else if (
              result?.status === "FAILED" &&
              result?.message ===
                "you are already signed-up with google account"
            ) {
              message = result.message;
            } else if (
              result?.status === "FAILED" &&
              result?.message ===
                "you are already signed-up with facebook account"
            ) {
              message = result.message;
            }

            if (message) {
              setDialogMessage(message);
              setDialogOpen(true);
            }
          }
        }
      }
    } catch (error) {
      logger.error("Error registering user:", error);
    }
  };

  const [isGetuserDone, setGetUserDone] = useState(false);

  const getUser = async () => {
    if (isGetuserDone) {
      return;
    }
    setGetUserDone(true);
 // This will be 'LOCAL', 'DEV', or 'PROD'
    const base_Url = getApiUrl(process.env.REACT_APP_URL);

    const url = `${base_Url}/api/auth/login/success`;
    const deviceInfo = await fetchDeviceAndIPInfo();
    // this constains device info contain city,country,deviceModel,deviceBrand how to send them in header and access them
    try {
      const response = await axios.post(url, {
        headers: {
          'Device-Brand': deviceInfo.deviceBrand, // Include Device Brand
          'Device-Model': deviceInfo.deviceModel, // Include Device Model
          'City': deviceInfo.city, // Include City
          'State': deviceInfo.state, // Include Country
        },
        withCredentials: true,
      });
      const { data } = response;
      if (!data.error) {
        // saveDataToLocalStorage("user", {
        //   email: data.user.email,
        //   token: data.token,
        //   user: data.user,
        // });
        navigate && typeof navigate === "function" && navigate("/notes");
      }
    } catch (error) {
      logger.error("Error fetching user data:", error);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await googleLogin();
    } catch (error) {
      logger.error("Error registering user:", error);
    }
  };

  const handleFacebookLogin = async () => {
    try {
      await facebookLogin();
    } catch (error) {
      logger.error("Error registering user:", error);
    }
  };

  const handleForgotPassword = () => {
    navigate("/forgotpassword");
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();

    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setpasskeydialogOpen(false);
  };

  const handleClickSignInWithPasskey = () =>{
    setdialogOfPasskey(true);
  }

  const handleClosePasskeyDialog = ()=>{
    setdialogOfPasskey(false);
  }

 

  const handleEmailChange = (e:any) =>{
    setemail(e.target.value)
  }

/**
 * @description Handles the authentication process for a user using passkeys. It initiates the authentication, verifies the user's credentials, 
 * retrieves device information, and then logs the user in by sending the relevant data to the server. If the user is not found, it opens a dialog for further action.
 * 
 * @async
 * @function handleAuthenticatePasskey
 * 
 * @returns {Promise<void>} This function does not return a value but manages the login process for users using passkeys, 
 * including navigating to the notes page upon successful login or handling user not found scenarios.
 */
const handleAuthenticatePasskey = async () => {
  // console.log("calling login");

  // 1. Initialize authentication options for the user
  const options = await passkeyInitAuth(email);

  if (options.message === "User not found") {
      setpasskeydialogOpen(true); // Open dialog if user not found
      return;
  }

  // 2. Try to authenticate the user
  try {
      const authJSON = await startAuthentication(options);
      // console.log(authJSON); 

      const verifyData = await passkeyVerifyAuth(authJSON);
      
      // If verification is successful
      if (verifyData.message === "true") {
          // Fetch device and IP information
          const deviceInfo = await fetchDeviceAndIPInfo();
          if (!deviceInfo) {
              // console.log("Failed to retrieve device and IP info");
              return; // Exit if device info retrieval fails
          }
          const payload = {
              email: email,
              deviceBrand: deviceInfo.deviceBrand,
              deviceModel: deviceInfo.deviceModel,
              city: deviceInfo.city,
              state: deviceInfo.state,
          };

          // Send the payload to the server for login
          const result = await passkeyLogin(payload);
          // console.log(result); // Log the result for debugging
          // console.log(result.data.user); // Log the user data

          // Dispatch the user information to the store
          const userVal = result.data.user;
          dispatch(userActions.setUser(userVal));

          logger.info("User has successfully logged in using passkeys");

          // Navigate to the notes page
          navigate("/notes");
      } else {
          // Handle the case where verification fails
      }
  } catch (e) {
      // Handle errors during the authentication process
  }
};


/**
 * @description Verifies the provided token for the user by sending it to the server. If the token is valid, the user is navigated to the notes page. Otherwise, it displays an error message.
 * 
 * 
 * @returns {void} This function does not return a value .
 */
const handleVerifyToken = async () => {

  // console.log(userFromStore.data?.id);
  // console.log(token);


  const payload = {
    id: userFromStore.data?.id,
    token: token,
  };

  // Send a request to the server to verify the token
  const result = await verifyToken(payload);
  // console.log(result); 


  if (result.message === "true") {
    logger.info("User successfully completed 2FA during the login process");
    navigate("/notes");
  } 
 
  else {
    setTokenError("Invalid token");
  }
};


  const handleClose2FADialog = () =>{
    setDialogof2FA(false);
  }

  const handleChangeToken = (e:any) =>{
    setToken(e.target.value);
  }
  return (
    <>
      <ProductAppBar showMenu={false} />
      <Grid container spacing={2} sx={{ height: "88%", margin: 0, width:"auto" }}>
        <CssBaseline />
        {isMdUp && <LogoComponent />}
        <Grid
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            backgroundImage: globalData.mode === 'dark' ?
            "linear-gradient(153.69deg, #005bb5, rgba(0, 0, 0, 0))"
             : "linear-gradient(153.69deg, #0085fe, rgba(255, 255, 255, 0))",
          }}
        >
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" sx={{ color: "white" }}>
              Sign in
            </Typography>
            <Typography component={"h1"} variant="h5" sx={{ color: "white" }}>
              Revolutionize Patient Care.
            </Typography>
          </Box>
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              flex: "0 0 100%",
              mb: 5,
              "@media (min-width: 750px) and (max-width: 900px)": {
                  paddingLeft:"130px",
                  paddingRight:"130px"
                },
              "@media (min-width: 600px) and (max-width: 749px)": {
                  paddingLeft:"60px",
                  paddingRight:"60px"
                }
            }}
          >
            <FormGroup
              sx={{
                display: "flex",
                justifyContent: "center",
                flex: "0 0 100%",
                
              }}
            >
              {!emailVerfied}
              <SignUpAndLogin
                isLogin={true}
                onEmailChange={handleContactChange}
                email={contact}
                emailError={
                  errorMessage !== "" || !emailVerfied || showErrorMessage
                }
                emailHelperText={
                  !isContactValid
                    ? "Please enter a valid email."
                    : errorMessage
                    ? errorMessage
                    : !emailVerfied
                    ? "Email hasn't been verifed yet. Check your inbox."
                    : ""
                }
                isPasswordValid={isPasswordValid}
                password={password}
                handlePasswordChange={handlePasswordChange}
                handleKeyPress = {handleKeyPress}
              />
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                }}
              >
                <Button
                  type="submit"
                  fullWidth
                  disabled={!isContactValid || !isPasswordValid}
                  variant="contained"
                  sx={{ m: 1 }}
                  onClick={handleSubmit}
                >
                  Sign In
                </Button>
              </Box>
              <Grid sx={{ m: 1 }}>
                <Grid sm={10}>
                  <Link2
                    onClick={handleForgotPassword}
                    variant="body2"
                  >
                    <Button
                    sx={{bgcolor:"transparent",
                      "&:hover":"transparent",
                    }}>
                      Forgot password?
                      </Button> 
                  </Link2>
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "& > *": {
                    m: 1,
                  },
                }}
              >

                <Divider component="div" role="presentation">
                  <Typography variant="h6" color="primary" onClick={handleClickSignInWithPasskey} sx={{cursor:"pointer"}}>
                    Sign In with Passkeys
                  </Typography>
                </Divider>

                <Divider component="div" role="presentation">
                  <Typography variant="h6" color="primary">
                    Or Sign In using
                  </Typography>
                </Divider>
                

                <ButtonGroup
                  variant="text"
                  aria-label="Basic button group"
                  sx={{ marginTop: 5 }}
                >
                  <Button onClick={handleGoogleLogin}>
                    <GoogleIcon sx={{ fontSize: 40 }}></GoogleIcon>
                  </Button>
                  <Button onClick={handleFacebookLogin}>
                    <FacebookIcon sx={{ fontSize: 40 }} />
                  </Button>
                </ButtonGroup>
              </Box>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link2>
                  {" "}
                  <Link
                    to="/register"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      fontSize: "20px",
                    }}
                  >
                    <Typography
                      component={"h6"}
                      variant="h6"
                      sx={{ color: "#0085FE" }}
                    >
                      {"Don't have an account? Sign up"}
                    </Typography>
                  </Link>
                </Link2>
              </Grid>
            </FormGroup>
          </Container>
        </Grid>
      </Grid>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            // backgroundColor: "white",
            backgroundColor:  globalData.mode === 'dark' ? "000000": "white",
            borderRadius: 15,
            border: "2px solid dodgerblue",
            padding: "20px",
          },
        }}
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", color:globalData.mode === 'dark' ? DarkMode.titleAndText: '#044cae', fontWeight: 'bold', fontSize: '1.2rem' }}
        >
          {dialogMessage}
        </DialogTitle>
        <DialogActions>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%"}}>
            <Button onClick={handleCloseDialog} color="primary" variant="contained"
            sx={{
              color:globalData.mode === 'dark' ? "Black": "white",
              backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
              '&:focus, &:hover': {
                backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
                color: globalData.mode === 'dark' ?  "black" : "White",
              }}}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Passkey dialog start */}

      <Dialog
          open={dialogOfPasskey}
          onClose={handleClosePasskeyDialog}
          PaperProps={{
            style: {
            backgroundColor:  globalData.mode === 'dark' ? "black": "white",
            // backgroundColor: "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
              width:"400px"
            },
          }}
        >
          <DialogTitle 
          sx={{
            color:globalData.mode === 'dark' ? DarkMode.titleAndText: "Black",}}
            >Login With Passkeys</DialogTitle>
          <DialogContent>
            <TextField
              label="Enter the Registered Email ID"
              name="username"
              type='text'
              fullWidth
              value={email}
              onChange={handleEmailChange}
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePasskeyDialog} color="primary" variant="contained"
             sx={{
              color:globalData.mode === 'dark' ? "Black": "white",
              backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
              '&:focus, &:hover': {
                backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
                color: globalData.mode === 'dark' ?  "black" : "White",
              }}}>
              Cancel
            </Button>
            <Button onClick={handleAuthenticatePasskey} color="primary" variant="contained"
             sx={{
              color:globalData.mode === 'dark' ? "Black": "white",
              backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
              '&:focus, &:hover': {
                backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
                color: globalData.mode === 'dark' ?  "black" : "White",
              }}}>
              Login
            </Button>
          </DialogActions>
        </Dialog>

        {/* passkey dialog end */}

        {/* passkey Not enabled dialog start */}

        <Dialog
        open={passkeydialogOpen}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            // backgroundColor: "white",
            backgroundColor:  globalData.mode === 'dark' ? "000000": "white",
            borderRadius: 15,
            border: "2px solid dodgerblue",
            padding: "20px",
          },
        }}
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", color:globalData.mode === 'dark' ? DarkMode.titleAndText: '#044cae', fontWeight: 'bold', fontSize: '1.2rem' }}
        >
          Passkey Has Not Enabled
        </DialogTitle>
        <DialogActions>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%"}}>
            <Button onClick={handleCloseDialog} color="primary" variant="contained"
            sx={{
              color:globalData.mode === 'dark' ? "Black": "white",
              backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
              '&:focus, &:hover': {
                backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
                color: globalData.mode === 'dark' ?  "black" : "White",
              }}}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
        {/* passkey Not enabled dialog end */}



        {/* 2FA dialog start  */}


        <Dialog
          open={dialogOf2FA}
          onClose={handleClose2FADialog}
          PaperProps={{
            style: {
            backgroundColor:  globalData.mode === 'dark' ? "black": "white",
            // backgroundColor: "white",
              borderRadius: 15,
              border: "2px solid dodgerblue",
              padding: "20px",
              width:"400px"
            },
          }}
        >
          <DialogTitle 
          sx={{
            color:globalData.mode === 'dark' ? DarkMode.titleAndText: "Black",}}
            >Two Factor Authentication</DialogTitle>
          <DialogContent>
            <TextField
              label="Enter 2FA Code"
              name="2FA token"
              type='password'
              fullWidth
              value={token}
              onChange={handleChangeToken}
              margin="normal"
              helperText={tokenError}
              FormHelperTextProps={{
                style: { color: 'red' },
              }}
              inputProps={{
                autoComplete: 'one-time-code'
             }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose2FADialog} color="primary" variant="contained"
             sx={{
              color:globalData.mode === 'dark' ? "Black": "white",
              backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
              '&:focus, &:hover': {
                backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
                color: globalData.mode === 'dark' ?  "black" : "White",
              }}}>
              Cancel
            </Button>
            <Button onClick={handleVerifyToken} color="primary" variant="contained"
             sx={{
              color:globalData.mode === 'dark' ? "Black": "white",
              backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
              '&:focus, &:hover': {
                backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
                color: globalData.mode === 'dark' ?  "black" : "White",
              }}}>
              Login
            </Button>
          </DialogActions>
        </Dialog>



        {/* 2FA dialog end  */}
    </>
  );
};

export default LoginAccount;
