import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React from 'react';
import DarkMode from '../utils/color/DarkMode';
import { useData } from "../context/dataContext";
import LightMode from '../utils/color/LightMode';
interface AudioRecorderDialogBoxProps {
  open: boolean;
  handleOutsideClick: (value: boolean) => void;
  handleCloseDialog: (value: boolean) => void;
  handleConfirmDialog: (value: boolean) => void;
  message : string;
  cancelButtonName : string;
  confirmButtonName : string;
  title : string;
}

const CustomizableDialogBox : React.FC<AudioRecorderDialogBoxProps> = ({ title, open, handleOutsideClick, handleCloseDialog, handleConfirmDialog , message , cancelButtonName, confirmButtonName }) => {
  const { globalData } = useData();
  const mode = globalData.mode === 'dark' ? DarkMode : LightMode;
  return (
    <Box>
        <Dialog
        open={open}
        onClose={handleOutsideClick}
        PaperProps={{
          style: {
            borderRadius: 15,
            border: `2px solid ${mode.DB_BorderLine}`,
            padding: "20px",
            backgroundColor: mode.DB_Background,
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "center", alignItems: "center",color: mode.DB_MainTitle}}>
          {title}
        </DialogTitle>
        <DialogContent  sx={{ color: mode.DB_TextColor }}>
          {message}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button onClick={()=>handleCloseDialog(false)}  variant="contained"
               sx={{
                color: mode.DB_ButtonText,
                backgroundColor: mode.DB_ButtonBackground,
                '&:focus, &:hover': {
                  backgroundColor: mode.DB_ButtonBackground,
                  color: mode.DB_ButtonText,
                }
              }}
                >
              {cancelButtonName}
            </Button>
            <Button onClick={()=>handleConfirmDialog(true)} color="primary" variant="contained"
                sx={{
                  color: mode.DB_ButtonText,
                  backgroundColor: mode.DB_ButtonBackground,
                  '&:focus, &:hover': {
                    backgroundColor: mode.DB_ButtonBackground,
                    color: mode.DB_ButtonText,
                  }
                }}
                >
              {confirmButtonName}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default CustomizableDialogBox;