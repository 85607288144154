const LightMode = {
    DB_MainTitle: "#0085FE",
    DB_TextColor: "#000000",
    DB_ButtonBackground: "#0085FE",
    DB_ButtonText: "#FFFFFF",
    DB_BorderLine: "#0085FE",
    DB_Background: "#FFFFFF",
    iconColor: "#666666",
    iconColorOnSelection: "#1990FF",
    titleAndText: "#0085FE",
    titleAndTextOnSelection: "#1990FF",
    titleInsideFieldSelection:"",// need to add
subTitlesOnFieldSelection:"",// need to add
    scrollBarBackground: "#BEE0FF",
    scrollBarTab: "#0085FE",
    selectionBackground: "#DCDCDC",
    iconOnSelection: "#1990FF",
    notesFieldTitleAndTexts: "#000000",
    notesFieldOnSelectionGlowingBorder: "#0085FE",
    noteFieldBackgroundOnSelection: "", // need to add
    border: "#1990FF",
    headingBackground: "#81C3FF",
    headingText: "#16222C",
    rowBackground: ["#D7ECFF", "#FFFFFF"],
    rowText: "#000000",
    rightSubBackground: "", // need to add 
    buttonBackground: "#D7ECFF",
    buttonText: "#1990FF",
    buttonBackgroundOnSelection: "#1990FF",
    buttonTextOnSelection: "#D7ECFF",
    defaultIcons: "#0085FE",
    categoryMenuBackground:"", // need to add 
    iconsOnSelection: "#1990FF",
    title:"",// need to add 
    titleOnSelection: "#1990FF",
  };
  
  export default LightMode;
  