// Defining custom log levels with their respective numeric values.
import axios from "axios";
import getApiUrl from "../components/config";

const LEVELS = {
    fatal: 1,   
    error: 2,   
    warn: 3,    
    info: 4,   
    debug: 5    
  };
  
const currentlevel = 'fatal';    // Current log level for browser console output
const loggingLevel = 'info';     // Current log level for backend logging

let logMsgs: any = [];

/**
 * @description :  Formats a log message for consistent output.
 * 
 * @param : The level of the log (e.g., 'info', 'error').
 * @param :  The messages to be logged.
 * 
 * @returns :  A formatted log message.
 */
const formatMessage = (level: keyof typeof LEVELS, ...messages: any[]) => {
  return `${messages.join(' ')}`;
};

/**
 * @description  : Logs messages based on the current logging levels. If the message's log level meets 
 *              the required level, it will be logged to the console or added to the backend queue.
 * 
 * @param :  The level of the log (e.g., 'info', 'error').
 * @param : The messages to be logged.
 * 
 * @returns : void
 */
const logMessage = async (level: keyof typeof LEVELS, ...messages: any[]) => {
  const logLevel = LEVELS[level];
  let formattedMessage;

  // Check if the log should be printed to the browser console based on the current level
  if (LEVELS[currentlevel] >= logLevel) {
    formattedMessage = formatMessage(level, ...messages);
    console.log(formattedMessage);
  }

  // Check if the log should be sent to the backend based on the logging level
  if (LEVELS[loggingLevel] >= logLevel) {
    formattedMessage = formatMessage(level, ...messages);
    const obj = {
      level: loggingLevel,  
      msglevel: level,     
      message: formattedMessage  
    };
    logMsgs.push(obj);

    // If there are 5,  send them to the backend
    if (logMsgs.length === 5) {
      await sendMessages();
    }
  }
};

/**
 * @description Sends log messages to the backend API when the buffer reaches 5 messages or on window unload.
 * 
 * @returns : void
 */
async function sendMessages() {
  // console.log(logMsgs)
  const payload = {
    messages: logMsgs
  };
  logMsgs = [];  // Clear the log message queue after sending
  const SERVER_URL = getApiUrl(process.env.REACT_APP_URL);
  try{

  let response = await axios.post(`${SERVER_URL}/api/log`, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  }
  catch(e){
    
  }
}

// Defining logger object with methods for different log levels . 
const logger = {
  error: async (...messages: any[]) => {
    logMessage('error', messages.map(m => JSON.stringify(m)).join(' '));
  },
  warn: async (...messages: any[]) => {
    logMessage('warn', messages.map(m => JSON.stringify(m)).join(' '));
  },
  info: async (...messages: any[]) => {
    logMessage('info', messages.map(m => JSON.stringify(m)).join(' '));
  },
  debug: async (...messages: any[]) => {
    logMessage('debug', messages.map(m => JSON.stringify(m)).join(' '));
  },
  fatal: async (...messages: any[]) => {
    logMessage('fatal', messages.map(m => JSON.stringify(m)).join(' '));
  }
};

// If a user has generated fewer than 5 messages and closes the browser, those messages will not be sent to the server. This function is called 
// when the user closes the browser, preventing the loss of any generated messages.

window.addEventListener('beforeunload', () => {
  sendMessages();
});

export default logger;