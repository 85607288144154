import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { useData } from '../context/dataContext';
import DarkMode from '../utils/color/DarkMode';
import LightMode from '../utils/color/LightMode';

interface IdleDialogProps {
  open: boolean;
  onClose: () => void;
  onKeepSignedIn: () => void;
}


const IdleDialog: React.FC<IdleDialogProps> = ({ open, onClose, onKeepSignedIn }) => {
  const { globalData } = useData();
  const mode = globalData.mode === 'dark' ? DarkMode : LightMode;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: 15,
          border: `2px solid ${mode.DB_BorderLine}`,
          padding: "20px",
          backgroundColor: mode.DB_Background,
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
          backdropFilter: "none",
        },
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "center", alignItems: "center",color: mode.DB_MainTitle }}>Session Timeout</DialogTitle>
      <DialogContent  sx={{ color: mode.DB_TextColor }}>
        Your session is about to expire due to inactivity. Do you want to keep your session active?
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '10px' }}>
        <Button onClick={onKeepSignedIn} variant="contained" autoFocus
        sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>Keep me signed in</Button>
        <Button onClick={onClose} color="primary" variant="contained" autoFocus 
        sx={{
          color:globalData.mode === 'dark' ? "Black": "white",
          backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColor : '#0085FE',
          '&:focus, &:hover': {
            backgroundColor: globalData.mode === 'dark' ? DarkMode.iconColorOnSelection : "#0085FE",
            color: globalData.mode === 'dark' ?  "black" : "White",
          }}}
          >Log out</Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdleDialog;
